import React from "react";
import { Table } from "react-bootstrap";

const Logs = () => {
  return (
    <div>
      <h1 className="mt-4">Logs</h1>
      <Table striped bordered hover className="mt-4">
        <thead>
          <tr>
            <th>#</th>
            <th>Event</th>
            <th>Date</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Login</td>
            <td>2024-07-20</td>
            <td>User John Doe logged in</td>
          </tr>
          {/* Add more rows as needed */}
        </tbody>
      </Table>
    </div>
  );
};

export default Logs;
