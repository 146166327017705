import "./Navbar.css";
import React from 'react';

const Banner = ({ text, color, imageUrl, customStyle }) => {
  return (
    <div className="banner">
      <img className='img' src={imageUrl} alt="Banner" />
      <div className="text-banner">
        <h2>{text}</h2>
      </div>
    </div>
  );
};

export default Banner;