import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { Table, Form, Button, Pagination } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const ViewScoresv2 = () => {
  const [employees, setEmployees] = useState([]);
  const [filters, setFilters] = useState({
    maMonHoc: "",
    maNV: "",
    startDate: "",
    endDate: "",
    phongBan: "",
    boPhan: "",
    status: "",
  });
  const [phongBans, setPhongBans] = useState([]);
  const [boPhans, setBoPhans] = useState([]);
  const [monHocs, setMonHocs] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));

    if (name === "phongBan") {
      fetchBoPhans(value);
    }
  }, []);

  const exportToExcel = useCallback(async () => {
    try {
      const response = await axios.get(
        "https://server.vietlonghung.com.vn/exportKetQua",
        // "http://localhost:30009/exportKetQua",
        {
          responseType: "blob",
          params: filters,
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `DanhSach_${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Lỗi khi tải file:", error);
    }
  }, [filters]);

  const fetchEmployees = useCallback(
    async (filterParams = {}) => {
      try {
        const response = await axios.get(
          "https://server.vietlonghung.com.vn/ketquas",
          {
            params: { ...filterParams, page, limit: 100 },
          }
        );
        setEmployees(response.data.employees);
        setTotalPages(response.data.totalPages);
        console.log(response.data.employees);
      } catch (error) {
        console.error("Lỗi khi lấy danh sách nhân viên:", error);
      }
    },
    [page]
  );

  const fetchPhongBans = useCallback(async () => {
    try {
      const response = await axios.get(
        "https://server.vietlonghung.com.vn/phongbans"
      );
      const sortedPhongBans = response.data.sort((a, b) =>
        a.TenPhongBan.localeCompare(b.TenPhongBan, "vi", {
          sensitivity: "base",
        })
      );
      setPhongBans(sortedPhongBans);
    } catch (error) {
      console.error("Lỗi khi lấy danh sách Phòng ban:", error);
    }
  }, []);

  const fetchBoPhans = useCallback(async (_id) => {
    try {
      const response = await axios.get(
        `https://server.vietlonghung.com.vn/bophans/${_id}`
      );
      const sortedBoPhans = response.data.sort((a, b) =>
        a.TenBoPhan.localeCompare(b.TenBoPhan, "vi", { sensitivity: "base" })
      );
      setBoPhans(sortedBoPhans);
    } catch (error) {
      console.error("Lỗi khi lấy danh sách Bộ phận:", error);
    }
  }, []);

  const fetchMonHocs = useCallback(async () => {
    try {
      const response = await axios.get(
        "https://server.vietlonghung.com.vn/monhoc"
      );
      const sortedMonHocs = response.data.sort((a, b) =>
        a.TenMonHoc.localeCompare(b.TenMonHoc, "vi", { sensitivity: "base" })
      );
      setMonHocs(sortedMonHocs);
    } catch (error) {
      console.error("Lỗi khi lấy danh sách Môn học:", error);
    }
  }, []);

  useEffect(() => {
    fetchEmployees(filters);
    fetchPhongBans();
    fetchMonHocs();
  }, [fetchEmployees, fetchPhongBans, fetchMonHocs]);

  const handleFilterClick = useCallback(() => {
    setPage(1);
    fetchEmployees(filters);
  }, [filters, fetchEmployees]);

  const handlePageChange = useCallback((newPage) => {
    setPage(newPage);
  }, []);

  const renderPagination = useCallback(() => {
    const pageNumbers = [];
    const maxPageItems = 5;
    let startPage = Math.max(1, page - Math.floor(maxPageItems / 2));
    let endPage = Math.min(totalPages, startPage + maxPageItems - 1);

    if (endPage - startPage + 1 < maxPageItems) {
      startPage = Math.max(1, endPage - maxPageItems + 1);
    }

    if (startPage > 1) {
      pageNumbers.push(
        <Pagination.Item key={1} onClick={() => handlePageChange(1)}>
          1
        </Pagination.Item>
      );
      if (startPage > 2) {
        pageNumbers.push(<Pagination.Ellipsis key="start-ellipsis" />);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Pagination.Item
          key={i}
          active={i === page}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push(<Pagination.Ellipsis key="end-ellipsis" />);
      }
      pageNumbers.push(
        <Pagination.Item
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
        >
          {totalPages}
        </Pagination.Item>
      );
    }
    return (
      <Pagination className="justify-content-center">
        <Pagination.First
          onClick={() => handlePageChange(1)}
          disabled={page === 1}
        />
        <Pagination.Prev
          onClick={() => handlePageChange(page - 1)}
          disabled={page === 1}
        />
        {pageNumbers}
        <Pagination.Next
          onClick={() => handlePageChange(page + 1)}
          disabled={page === totalPages}
        />
        <Pagination.Last
          onClick={() => handlePageChange(totalPages)}
          disabled={page === totalPages}
        />
      </Pagination>
    );
  }, [page, totalPages]);

  return (
    <div className="container">
      <h2 className="my-4">Kết quả của bạn</h2>
      <Form className="mb-4 d-block p-2">
        <div className="row">
          <div className="col-md-3">
            <Form.Group controlId="maMonHoc">
              <Form.Label>Tên môn học</Form.Label>
              <Form.Control
                as="select"
                name="maMonHoc"
                value={filters.TenMonHoc}
                onChange={handleChange}
              >
                <option value="">Tất cả môn học</option>
                {monHocs.map((TenMonHoc) =>
                  TenMonHoc.trangthai === 1 ? (
                    <option key={TenMonHoc._id} value={TenMonHoc._id}>
                      {TenMonHoc.TenMonHoc}
                    </option>
                  ) : null
                )}
              </Form.Control>
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group controlId="maNV">
              <Form.Label>Mã Nhân Viên</Form.Label>
              <Form.Control
                type="text"
                name="maNV"
                placeholder="Nhập Mã NV"
                value={filters.maNV}
                onChange={handleChange}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group controlId="startDate">
              <Form.Label>Từ ngày</Form.Label>
              <Form.Control
                type="date"
                name="startDate"
                value={filters.startDate}
                onChange={handleChange}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group controlId="endDate">
              <Form.Label>Đến ngày</Form.Label>
              <Form.Control
                type="date"
                name="endDate"
                value={filters.endDate}
                onChange={handleChange}
              />
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Form.Group controlId="phongBan">
              <Form.Label>Phòng ban</Form.Label>
              <Form.Control
                as="select"
                name="phongBan"
                value={filters.phongBan}
                onChange={handleChange}
              >
                <option value="">Tất cả phòng ban</option>
                {phongBans.map((phongBan) => (
                  <option key={phongBan._id} value={phongBan._id}>
                    {phongBan.TenPhongBan}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group controlId="boPhan">
              <Form.Label>Bộ phận</Form.Label>
              <Form.Control
                as="select"
                name="boPhan"
                value={filters.boPhan}
                onChange={handleChange}
              >
                <option value="">Tất cả bộ phận</option>
                {boPhans.map((boPhan) => (
                  <option key={boPhan._id} value={boPhan._id}>
                    {boPhan.TenBoPhan}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group controlId="status">
              <Form.Label>Trạng thái</Form.Label>
              <Form.Control
                as="select"
                name="status"
                value={filters.status}
                onChange={handleChange}
              >
                <option value="">Tất cả</option>
                <option value="done">Đã làm</option>
                <option value="notDone">Chưa làm</option>
              </Form.Control>
            </Form.Group>
          </div>
          <div className="col-md-3 align-self-end ">
            <Button
              variant="primary"
              className="mt-4 w-100"
              onClick={handleFilterClick}
            >
              Lọc
            </Button>
          </div>
          <div className="mt-4 text-center w-100">
            <Button onClick={exportToExcel} variant="success">
              Xuất Excel
            </Button>
          </div>
        </div>
      </Form>
      <Table bordered hover>
        <thead>
          <tr>
            <th>Mã NV</th>
            <th>Tên nhân viên</th>
            <th>Mã môn học</th>
            <th>Bộ phận</th>
            <th>Trạng thái</th>
            <th>Ngày làm</th>
          </tr>
        </thead>
        <tbody>
          {employees.length > 0 ? (
            employees.map((employee) => (
              <tr key={`${employee._id}-${employee.MaMonHoc}`}>
                <td>{employee.MaNV}</td>
                <td>{employee.TenNV || ""}</td>
                <td>{employee.MaMonHoc}</td>
                <td>{employee._idBoPhan?.TenBoPhan || "N/A"}</td>
                <td>{employee.Diem ? employee.Diem : "Chưa làm"}</td>
                <td>{employee.NgayLam ? employee.NgayLam : "Chưa làm"}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" className="text-center">
                Không có dữ liệu
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {renderPagination()}
    </div>
  );
};

export default ViewScoresv2;
