import React from "react";
import { Link } from "react-router-dom";
import {
  FaTachometerAlt,
  FaCog,
  FaUserTie,
  FaBook,
  FaRegQuestionCircle,
} from "react-icons/fa";

const Sidebar = () => {
  return (
    <div className="bg-dark border-right" id="sidebar-wrapper">
      <div className="sidebar-heading text-white">Admin Dashboard</div>
      <div className="list-group list-group-flush">
        <Link
          to="/admin"
          className="list-group-item list-group-item-action bg-dark text-white"
        >
          <FaTachometerAlt className="mr-2" /> Dashboard
        </Link>
        <Link
          to="/admin/monhoc"
          className="list-group-item list-group-item-action bg-dark text-white"
        >
          <FaBook className="mr-2" /> Quản lý môn học
        </Link>
        <Link
          to="/admin/cauhoi"
          className="list-group-item list-group-item-action bg-dark text-white"
        >
          <FaRegQuestionCircle className="mr-2" /> Quản lý câu hỏi
        </Link>
        <Link
          to="/admin/setting"
          className="list-group-item list-group-item-action bg-dark text-white"
        >
          <FaCog className="mr-2" /> Đổi mật khẩu
        </Link>
        {/* <Link
          to="/admin/logs"
          className="list-group-item list-group-item-action bg-dark text-white"
        >
          <FaFileAlt className="mr-2" /> Logs
        </Link> */}
      </div>
      <div
        className="text-white p-3 "
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "#343a40",
        }}
      >
        <FaUserTie className="mr-2 " /> By Việt Long Hưng
      </div>
    </div>
  );
};

export default Sidebar;
