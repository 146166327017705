import "./ccdt.css";

const Ctdt = () => {
  return (
    <>
      <div className="_sk">
        <img className="sk" src="https://i.imgur.com/4gi10al.jpeg" />
      </div>
    </>
  );
};

export default Ctdt;
