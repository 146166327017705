import React from "react";
import { Link } from "react-router-dom";
import "./Unauthorized.css"; // Đảm bảo tạo file CSS này

const Unauthorized = () => {
  return (
    <div className="unauthorized-container">
      <div className="unauthorized-content">
        <h1 className="h1_un">403</h1>
        <h2 className="h2_un">Truy cập bị từ chối</h2>
        <p className="p_un">
          Xin lỗi, bạn không có quyền truy cập vào trang này.
        </p>
        <div className="actions">
          <Link to="/" className="btn-primary">
            Về trang chủ
          </Link>
          {/* <Link to="/login" className="btn-secondary">
            Đăng nhập lại
          </Link> */}
        </div>
      </div>
    </div>
  );
};

export default Unauthorized;
