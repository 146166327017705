import React, { useState, useEffect } from "react";
import axios from "axios";
import "./TCH.css";

function TieuChuanHoa() {
  return (
    <div className="_tch">
      {/* <img className="tch" src="https://i.imgur.com/sZ01Vxk.jpeg" /> */}
      <img className="tch" src="https://i.imgur.com/6WjCWqE.jpeg" />
    </div>
  );
}

export default TieuChuanHoa;
