// Home.js
import ReactPlayer from "react-player";
import React, { useState, useEffect } from "react";
import Banner from "../../components/Navbar/Banner";
import "../../components/Navbar/Navbar.css";
import "./Home.css";
import Box from "../../components/Navbar/Box";

const Home = () => {
  const [play, setPlay] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 600) {
        setPlay(true);
      } else {
        setPlay(false);
      }
    };

    // Gọi hàm handleResize lần đầu tiên khi component được render
    handleResize();

    // Đăng ký sự kiện lắng nghe thay đổi kích thước cửa sổ
    window.addEventListener("resize", handleResize);

    // Loại bỏ sự kiện lắng nghe khi component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <Banner imageUrl={"https://i.imgur.com/o03hEPx.png"} />
      <div className="home">
        <div className="video">
          <ReactPlayer
            width="100%"
            height="600px"
            className="video"
            url="https://www.youtube.com/watch?v=6CobX2LvYE0"
            loop
            playing={play ? false : true}
            controls={false}
          />
        </div>

        <div className="mb-video">
          <ReactPlayer
            width="100%"
            height="240px"
            url="https://www.youtube.com/watch?v=6CobX2LvYE0"
            loop
            playing={play ? true : false}
            controls={false}
          />
        </div>

        <Box
          num={
            <section class="section">
              <div class="title vn">
                Chào mừng bạn đến với hệ thống đào tạo Lean trực tuyến của công
                ty VIỆT LONG HƯNG
              </div>
              <div class="title en">
                Welcome to VLH Lean Learning Online System
              </div>
            </section>
          }
        />
        <Box
          num={
            <section class="section _section">
              <div className="_title_sub">
                <img className="_img" src="https://i.imgur.com/n2nwhm8.png" />
                <div class="title vn">
                  TẦM NHÌN -<span class="mb-en en"> VISION</span>
                </div>
                <div>
                  <p class="text vn">
                    Trở thành nhà sản xuất tin tưởng của thương hiệu hàng <br />{" "}
                    đầu thế giới
                  </p>
                  <br />
                  <p class="text en mb-text">
                    Become the trusted manufacturer of world's leading brands.
                  </p>
                </div>
              </div>

              <div className="_title_sub">
                <img className="_img" src="https://i.imgur.com/j6Yf6jq.png" />
                <div class="title vn">
                  SỨ MỆNH - <span class="en">MISSION</span>
                </div>
                <div>
                  <p class="text vn">
                    Không ngừng nâng cao Trách nhiệm xã hội, Văn hóa An toàn và
                    Chất lượng, Cải tiến liên tục và Phát triển Bền vững nhắm
                    mang chất lượng sản phẩm và dịch vụ vượt trội* đến khách
                    hàng.
                  </p>
                  <p class="text en">
                    Continuously improve Social Responsibility, Culture of
                    Safety and Quality, Continuous Improvement and Sustainable
                    Develepment to bring out standing products and services to
                    the customers.
                  </p>
                </div>
              </div>
            </section>
          }
        />
        <Box
          num={
            <section class="section">
              <div class="title vn center">
                GIÁ TRỊ CỐT LÕI - <span class="en">CORE VALUE</span>
              </div>
              <div className="_section">
                <div class="title-pro">
                  <img className="_img" src="https://i.imgur.com/nEz29gM.png" />
                  <h3 class="title-sub vn">
                    Liên tục đổi mới
                    <span className="mb-en en"> Constantly innovation:</span>
                  </h3>
                  <p class="text vn">
                    Chúng tôi không ngừng học hỏi, sáng tạo, liên tục đổi mới
                    phương thức quản lý, nỗ lực dẫn đầu công nghệ và sản xuất
                    sản phẩm mới
                  </p>
                  <p />
                  <p class="text en mb-text_en">
                    We constantly learn, create, continuouly innovate management
                    method, strive to lead in technology and produce new
                    products
                  </p>
                </div>
                <div class="title-pro">
                  <img className="_img" src="https://i.imgur.com/63j2oYl.png" />
                  <h3 class="title-sub vn">
                    Con người<span className="en"> People</span>
                  </h3>
                  <p class="mb-text text vn">
                    Chúng tôi mang đến môi trường làm việc An toàn, Đoàn kết,
                    Tin tưởng và Tôn trọng. Nhân viên của chúng tôi được truyền
                    cảm hứng, trao quyền, phát triển và được khen thưởng.
                  </p>
                  <p class="text en mb-text_ens">
                    We provide a safe, united, mutual trust and respect working
                    environment. Our employees are inspired, empowered,
                    developed and rewarded.
                  </p>
                </div>
                <div class="title-pro">
                  <img className="_img" src="https://i.imgur.com/71uCL5A.png" />
                  <h3 class="title-sub vn">
                    Khách hàng là trọng tâm
                    <span className="en"> Customer focus:</span>
                  </h3>
                  <p class="text vn">
                    Yếu tố then chốt trong hoạt động điều hành của chúng tôi là
                    cung cấp chất lượng sản phẩm dịch vụ vượt trội, gắn kết và
                    phát triển bền vững cùng khách hàng.
                  </p>
                  <p />
                  <p class="text en">
                    The key element in our operation management is to provide
                    outstanding quality of products and services, align suitable
                    development with our customers.
                  </p>
                </div>
              </div>
            </section>
          }
        />
      </div>
    </>
  );
};

export default Home;
