import React from "react";

const Overlay = ({ onSignUpClick, onSignInClick }) => {
  return (
    <div className="overlay_auth">
      <div className="overlay-panel overlay-right">
        <img src="https://i.imgur.com/O1meXnL.png" />
      </div>
    </div>
  );
};

export default Overlay; // Xuất mặc định (default export)
