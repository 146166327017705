import Header from "./Header";
import Sidebar from "./Sidebar";
import { Outlet } from "react-router-dom";

const AdminLayout = ({ handleLogout }) => {
  const tennv = localStorage.getItem("tennv");

  return (
    <div id="wrapper">
      <Sidebar />
      <div id="page-content-wrapper">
        <Header name={tennv} handleLogout={handleLogout} />
        <div className="container-fluid">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
