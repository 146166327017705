// About.js
import React from "react";
import "./About.css";

const About = () => {
  return (
    <>
      <div className="about">
        <div class="user">
          <img className="img-user" src="https://i.imgur.com/Dn07OPS.png" />
          <h3 class="name-user">Nguyễn Huỳnh Nguyệt Như</h3>
          <p class="title-user">Trưởng Phòng Công Nghệ Lean</p>
        </div>
        {/* <div class="user">
          <img className="img-user" src="https://i.imgur.com/XvtdTEc.jpeg" />
          <h3 class="name-user">Nguyễn Duy Khương</h3>
          <br />
          <p class="title-user">Phó Phòng Công Nghệ Lean</p>
        </div> */}
        <div class="user">
          <img className="img-user" src="https://i.imgur.com/HcX34tv.jpeg" />
          <h3 class="name-user">Võ Thị Hoàng Ngọc Lâm</h3>
          <p class="title-user">Phó Phòng QA</p>
        </div>
        <div class="user">
          <img className="img-user" src="https://i.imgur.com/QntaFbN.jpeg" />
          <h3 class="name-user">Võ Thị Thùy Trang</h3>
          <br />
          <p class="title-user">Nhân Viên Lean</p>
        </div>
        {/* <div class="user">
          <img className="img-user" src="https://i.imgur.com/vZZbPm4.jpeg" />
          <h3 class="name-user">Nguyễn Văn C</h3>
          <p class="title-user">Trưởng Phòng</p>
        </div> */}
      </div>
    </>
  );
};

export default About;
