import React from "react";
import { Link } from "react-router-dom";
import "./UnderDevelopment.css"; // Đảm bảo tạo file CSS này

const UnderDevelopment = () => {
  return (
    <div className="under-development-container">
      <div className="under-development-content">
        <h1 className="h1_dev">Đang phát triển</h1>
        <h2 className="h2_dev">Xin lỗi vì sự bất tiện này</h2>
        <p className="p_dev">
          Trang web đang trong quá trình phát triển. Chúng tôi sẽ sớm hoàn thành
          nó!
        </p>
        <div className="actions">
          <Link to="/ctdt" className="btn-primary">
            Quay lại khóa học
          </Link>
        </div>
      </div>
    </div>
  );
};

export default UnderDevelopment;
