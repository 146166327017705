import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import ReactPlayer from "react-player";

const MonHocNSComponent = () => {
  const [monHocData, setMonHocData] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showNotification, setShowNotification] = useState(false);
  const [videoEnded, setVideoEnded] = useState(false);
  const [play, setPlay] = useState(false);

  const _idNV = localStorage.getItem("_idNV");
  const idmonhoc = localStorage.getItem("idmonhoc");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 600) {
        setPlay(true);
      } else {
        setPlay(false);
      }
    };
    // Gọi hàm handleResize lần đầu tiên khi component được render
    handleResize();
    // Đăng ký sự kiện lắng nghe thay đổi kích thước cửa sổ
    window.addEventListener("resize", handleResize);

    // Loại bỏ sự kiện lắng nghe khi component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleVideoEnd = () => {
    setVideoEnded(true);
  };

  const handleShowForm = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `https://server.vietlonghung.com.vn/ketquans`,
        {
          _idMaNV: _idNV,
          trangthai: "Đã Xem",
          _idMonHoc: idmonhoc, // Assuming all questions are from the same subject
        }
      );
      setShowNotification(true);
    } catch (error) {
      console.error("Lỗi khi lưu kết quả:", error);
      alert("Đã xảy ra lỗi khi Xác nhận đã xem.");
    }
  };

  const url = `https://server.vietlonghung.com.vn/monhocns/${searchParams.get(
    "a"
  )}`;
  // const idmonhoc = localStorage.getItem("idmonhoc");

  useEffect(() => {
    const fetchMonHoc = async () => {
      try {
        const response = await axios.get(url);

        setMonHocData(response.data);
        console.log("met", response.data);
        // console.log(monHocData.questions);
      } catch (error) {
        console.error("Lỗi khi gọi API môn học:", error);
      }
    };

    fetchMonHoc();
  }, []);

  const handleCloseNotification = () => {
    setShowNotification(false);
    window.open("/ctdt/ns", "_top");
  };

  return (
    <>
      {monHocData && (
        <div className="ctdt_7llp">
          <div className="_7llp _7llpns">
            <div className="_7llp_sub"></div>
            <h2 className="_7llp_title">{monHocData.TenMonHoc}</h2>
            <p className="_7llp_texts text_monhoc">
              Mã môn học: {monHocData.MaMonHoc}
            </p>
            {/* <p className="_7llp_texts text_monhoc">
              Số câu hỏi: {monHocData.length}
            </p> */}

            <div className="_7llp_sub">
              {/* <p className="_7llp_texts">
                Hãy xem video về bài học ngày hôm nay nhé. Sau đó thì làm bài
                kiểm tra nào
              </p> */}
              <ReactPlayer
                width="100%"
                height="600px"
                className="video"
                url={monHocData.Video}
                playing={play ? false : true}
                controls={false}
                onEnded={handleVideoEnd}
              />

              <div className="mb-video">
                <ReactPlayer
                  width="100%"
                  height="240px"
                  url={monHocData.Video}
                  playing={play ? true : false}
                  controls={false}
                  onEnded={handleVideoEnd}
                />
              </div>
            </div>

            {videoEnded && (
              <button onClick={handleShowForm} className="btn_7llp">
                <span>Xác nhận đã học</span>
                <svg
                  viewBox="0 0 320 512"
                  height="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"></path>
                </svg>
              </button>
            )}
            {showNotification && (
              <div className="notification-overlay">
                <div className="notification-box">
                  <span>Bạn đã xác nhận xem xong bài giảng</span>
                  <button
                    className="close-button"
                    onClick={handleCloseNotification}
                  >
                    &times;
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default MonHocNSComponent;
