import React, { useState } from "react";
import { UserCircle, LogOut } from "lucide-react";

const Header = ({ name, handleLogout }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
 
  return (
    <nav className="navbaradmin navbar-expand-lg navbar-light bg-light border-bottom">
      <li className="nav-item list-group-item dropdown">
        <div
          className="nav-link dropdown-toggle"
          style={{ cursor: "pointer" }}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <UserCircle size={25} />
          <span className="ms-2 text-capitalize">{name}</span>
        </div>
        {isMenuOpen && (
          <ul className="dropdown-menu dropdown-menu-end show">
            <li>
              <button className="dropdown-item" onClick={handleLogout}>
                <LogOut size={18} className="me-2" />
                Đăng xuất
              </button>
            </li>
          </ul>
        )}
      </li>
    </nav>
  );
};

export default Header;
