import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap/dist/css/bootstrap-theme.css";
import Navbar from "./components/Navbar/Nav";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
  useLocation,
} from "react-router-dom";
import Home from "./Pages/Home/Home";
import About from "./Pages/About/About";
import Ctdt from "./Pages/ccdt/Ccdt";
import SuKien from "./Pages/ccdt/SuKien";
import TieuChuanHoa from "./Pages/TieuChuanHoa/TCH";
import AuthForm from "./components/Auth/AuthForm";
import Unauthorized from "./components/Auth/Unauthorized";
import UnderDevelopment from "./components/Auth/UnderDevelopment";
import MonHocComponent from "./components/BaiTest_1/MonHoc";
// import ViewScores from "./components/BaiTest_1/ViewScore";
import Dashboard from "./components/Admin/Dashboard";
import Setting from "./components/Admin/Setting";
import Logs from "./components/Admin/Logs";
import MonHocManagement from "./components/Admin/MonHocManagement.js";
import CauHoiManagement from "./components/Admin/CauHoiManagement.js";
import "./App.css";
import AdminLayout from "./components/Admin/AdminLayout.js";
import ViewScoresv2 from "./components/BaiTest_1/ViewScoreV2.js";
import MonHocNSComponent from "./components/BaiTest_1/BaiTestNS.js";
import CtdtNS from "./Pages/ccdt/CtdtNS.js";

// const ProtectedRoute = ({ allowedRoles }) => {
//   const token = localStorage.getItem("token");
//   const role = localStorage.getItem("role");

//   if (!token) {
//     return <Navigate to="/login" replace />;
//   }

//   if (allowedRoles.includes(role)) {
//     return <Outlet />;
//   } else {
//     return <Navigate to="/unauthorized" />;
//   }
// };

const ProtectedRoute = ({ allowedRoles, allowedDepartments }) => {
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const department = localStorage.getItem("phongban");

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  const isRoleAllowed = allowedRoles.includes(role);
  const isDepartmentAllowed = allowedDepartments
    ? allowedDepartments.includes(department)
    : true; // Mặc định là true nếu không cần kiểm tra Phòng Ban

  if (isRoleAllowed && isDepartmentAllowed) {
    return <Outlet />;
  } else {
    return <Navigate to="/unauthorized" />;
  }
};

function App() {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
}

function AppContent() {
  const location = useLocation();
  const idrole = localStorage.getItem("idrole");
  const maNV = localStorage.getItem("_idNV");

  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setLoggedIn(true);
    }
  }, []);
  const handleLogout = () => {
    localStorage.removeItem("phongban");
    localStorage.removeItem("role");
    localStorage.removeItem("token");
    localStorage.removeItem("idrole");
    localStorage.removeItem("idphongban");
    localStorage.removeItem("tennv");
    localStorage.removeItem("manv");
    setLoggedIn(false);
  };

  const isAdminRoute = location.pathname.startsWith("/admin");

  return (
    <>
      {loggedIn ? (
        <>
          {!isAdminRoute && <Navbar handleLogout={handleLogout} />}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<AuthForm />} />
            <Route path="/about" element={<About />} />
            <Route path="/unauthorized" element={<Unauthorized />} />
            <Route path="/underdevelopment" element={<UnderDevelopment />} />
            <Route
              element={
                <ProtectedRoute allowedRoles={["QL", "QD", "GD", "SUP"]} />
              }
            >
              <Route path="/vlh_ketqua7llp" element={<ViewScoresv2 />} />
            </Route>
            <Route
              element={
                <ProtectedRoute
                  allowedRoles={["QL", "QD", "GD", "SUP", "TT", "CN"]}
                />
              }
            >
              <Route
                path="/ctdt"
                element={<Ctdt userRole={idrole} MaNV={maNV} />}
              />
              <Route path="/vlh_sukien" element={<SuKien />} />
              <Route path="/vlh_tieuchuanhoa" element={<TieuChuanHoa />} />
              <Route
                path="/ctdt/questionscomponent"
                element={<MonHocComponent />}
              />
            </Route>
            <Route
              element={
                <ProtectedRoute
                  allowedRoles={["TT", "SUP", "QD", "GD", "SUP", "TT"]}
                  allowedDepartments={["002"]}
                />
              }
            >
              <Route
                path="/ctdt/ns"
                element={<CtdtNS userRole={idrole} MaNV={maNV} />}
              />
              <Route
                path="/ctdtns/questionscomponent"
                element={<MonHocNSComponent />}
              />
              {/* Các route NS khác */}
            </Route>

            <Route
              path="/admin"
              element={<AdminLayout handleLogout={handleLogout} />}
            >
              <Route
                path="/admin/unauthorized"
                element={<Unauthorized handleLogout={handleLogout} />}
              />
              <Route
                path="/admin/underdevelopment"
                element={<UnderDevelopment />}
              />
              <Route element={<ProtectedRoute allowedRoles={["QL", "SUP"]} />}>
                <Route path="/admin/monhoc" element={<MonHocManagement />} />
                <Route path="/admin/cauhoi" element={<CauHoiManagement />} />
                <Route index element={<Dashboard />} />
                <Route path="/admin/setting" element={<Setting />} />
                <Route path="/admin/logs" element={<Logs />} />
              </Route>
            </Route>
          </Routes>
        </>
      ) : (
        <AuthForm />
      )}
    </>
  );
}

export default App;
